body{
  /* background-color:aliceblue; */
  background-image: linear-gradient(to right, 
  aliceblue,
  rgb(125, 200, 224))
  
}
.info-register-form-wrapper
{
  align-items: center;
  align-content: center;
} 

.info-register-form{
  margin-left: auto;
  margin-right: auto;
}

.RegBtn
{
  margin-left: auto;
  margin-right: auto;
}

.BtnWrapper
{
  margin-left: auto;
  margin-right: auto;
  border: dashed 3px black;
  border-radius: 5%;
  background-color: rgb(226, 237, 247);
}

.error-msg-wrapper
{
  border:solid 2px rgb(221, 69, 100);
  border-radius: 20px;
  text-align: center;
  align-items: center;
  align-content: center;
  background-color: rgb(221, 69, 100);
  color: rgb(34, 26, 26);
  margin-left: auto;
  margin-right: auto;
}
.success-msg-wrapper
{
  border:solid 2px rgb(52, 211, 105);
  border-radius: 20px;
  text-align: center;
  align-items: center;
  align-content: center;
  background-color: rgb(52, 211, 105);
  color: rgb(34, 26, 26);
  margin-left: auto;
  margin-right: auto;
}

#UploadFile
{
  margin-left: auto;
  margin-right: auto;
}

.SumCard{
  background-color: rgb(255, 255, 255);
  border-radius: 10px;
  min-height: 250px; 
  min-width: 250px;
  max-height: 500px;
  transition-duration: 0.2s;
  cursor: pointer;
  box-shadow: 5px 5px  lightblue;
}

.SumCard:hover
{
  
  transition-property: background-color;
  background-color: #e9e9e9;
}

.show-comments{
  margin-left: auto;
  margin-right: auto;
}

.searchbar
{
  
}




